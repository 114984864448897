import React, { useEffect, useRef } from "react";
import { Link } from "react-scroll";
import "./css/NavigationBar.css";

const NavigationBar = () => {
  const mxwangRef = useRef(null);

  useEffect(() => {
    const element = mxwangRef.current;
    let times = 10;

    const shake = () => {
      if (times > 0) {
        // Alternate between moving the element left and right
        element.style.transform = `translateX(${
          times % 2 === 0 ? -0.5 : 0.5
        }vw)`;
        times--;
        setTimeout(shake, 50); // Adjust time for shake speed
      } else {
        element.style.transform = "translateX(0)";
      }
    };

    shake();
  }, []);

  const styles = {
    mxwang: {
      textAlign: "left",
      fontSize: "3vw", // Adjust text size based on screen width
      padding: "2vw",
      fontFamily: "RalewayBlack",
      transition: "transform 0.1s linear",
    },

    options: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },

    button: {
      fontSize: "1.2vw", // Adjust button text size based on screen width
      margin: "0 1vw", // Adjust button margin based on screen width
    },

    header: {
      boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1vw", // Adjust padding based on screen width
      height: "8vh", // Adjust height based on viewport height
      color: "white",
      zIndex: 2,
      position: "relative",
    },
  };

  return (
    <div style={styles.header}>
      <div ref={mxwangRef} style={styles.mxwang}>
        MXWANG
      </div>
      <div style={styles.options}>
        <Link to="about" smooth={true} duration={300}>
          <button className="button-options" style={styles.button}>
            About
          </button>
        </Link>
        <Link to="experience" smooth={true} duration={300}>
          <button className="button-options" style={styles.button}>
            Experience
          </button>
        </Link>
        <Link to="projects" smooth={true} duration={300}>
          <button className="button-options" style={styles.button}>
            Projects
          </button>
        </Link>
        <Link to="contact" smooth={true} duration={300}>
          <button className="button-options" style={styles.button}>
            Contact
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NavigationBar;
