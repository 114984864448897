import React from "react";
import "./css/Constellation.css";

/**
 * Generates a randomized array of star positions and ids.
 * @returns {Array} Array of star objects with id, top, and left properties
 */
const randomizeStars = () => {
  const AMOUNT = 200;
  const stars = [];

  for (let i = 1; i <= AMOUNT; i++) {
    stars.push({
      id: i,
      top: `${Math.random() * 100}%`,
      left: `${5 + Math.random() * 85}%`,
    });
  }

  return stars;
};

/**
 * Generates a Constellation background.
 * @returns {Array} Component to be placed in App.js, in charge of star generation
 */
const Constellation = () => {
  const stars = randomizeStars();
  const icons = ["✰", "☾", "⁺₊", "✧"];

  /**
   * Returns a random icon from the icons array.
   * @returns {string} A random icon
   */
  const getRandomIcon = () => {
    return icons[Math.floor(Math.random() * icons.length)]; // I love math random
  };

  return (
    <div style={styles.container}>
      {stars.map((star) => (
        <span
          key={star.id}
          style={{
            ...styles.star,
            top: star.top,
            left: star.left,
            animationDuration: `${2 + Math.random() * 3}s`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        >
          {getRandomIcon()}
        </span>
      ))}
    </div>
  );
};

const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "8000px", // Use 100vh for full viewport height
    color: "#fff",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    justifyContent: "center",
    overflow: "hidden",
  },
  star: {
    position: "absolute",
    fontSize: "20px",
    color: "yellow",
    animationName: "float",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
  },
};

export default Constellation;
