import React from "react";
import { Element } from "react-scroll";
import linkedinIcon from "./../images/linkedin-icon.png"; // Replace with your LinkedIn icon path
import emailIcon from "./../images/email-icon.png"; // Replace with your email icon path
import githubIcon from "./../images/github-icon.png"; // Replace with your GitHub icon path

const ContactPage = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    title: {
      fontSize: "1.8vw", // Responsive font size based on viewport width
      fontFamily: "RalewayBold",
      color: "#f7ea88",
      margin: "2vw", // Responsive margin based on viewport width
      padding: "2vw", // Responsive padding based on viewport width
      borderRadius: "2vw", // Responsive border radius based on viewport width
      textAlign: "center",
    },

    iconsContainer: {
      display: "flex",
      justifyContent: "center",
    },

    icon: {
      width: "3vw", // Responsive icon size based on viewport width
      height: "3vw", // Responsive icon size based on viewport width
      margin: "0 2vw", // Responsive spacing between icons based on viewport width
      cursor: 'url("../../images/cursor-hover.png"), auto !important',
    },
  };

  // Replace the href values with your actual social media links
  const socialLinks = {
    linkedin: "https://www.linkedin.com/in/michellexiawang",
    email: "mailto:michelle.wang3438@gmail.com",
    github: "https://github.com/skyleapa",
  };

  return (
    <Element name="contact">
      <div style={styles.container}>
        <div style={styles.title}>⋆｡°✩₊☾₊✩°｡⋆ Contact Me ⋆｡°✩₊☾₊✩°｡⋆</div>
        <div style={styles.iconsContainer}>
          <a
            href={socialLinks.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="LinkedIn" style={styles.icon} />
          </a>
          <a href={socialLinks.email} target="_blank" rel="noopener noreferrer">
            <img src={emailIcon} alt="Email" style={styles.icon} />
          </a>
          <a
            href={socialLinks.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={githubIcon} alt="GitHub" style={styles.icon} />
          </a>
        </div>
      </div>
    </Element>
  );
};

export default ContactPage;
