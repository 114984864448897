import React from "react";
import { Element } from "react-scroll";

export default function About() {
  const styles = {
    block: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center the block horizontally
      position: "relative",
      width: "60%",
      margin: "auto", // Center the block horizontally
    },

    container: {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Adjust the background color and opacity
      padding: "50px",
      borderRadius: "20px", // Set border radius for rounded corners
      margin: "20px auto", // Add margin for spacing
    },

    header: {
      fontSize: "1.8vw", // Dynamic font size based on screen width
      fontFamily: "RalewayBold",
      color: "#f7ea88",
      margin: "2%",
      textAlign: "center", // Align the text to the left
    },

    description: {
      fontSize: "1.2vw", // Dynamic font size based on screen width
      color: "white",
      textAlign: "center", // Align the text to the left
    },
  };

  return (
    <Element name="about">
      <div style={styles.block}>
        <div style={styles.container}>
          <div style={styles.header} class="floatingText">
            ✩₊˚.⋆☾⋆⁺ Greetings, cosmic wanderer! ✩₊˚.⋆☾⋆⁺
          </div>
          <div style={styles.description} class="floatingText">
            I'm Michelle, your guide through the celestial tapestry of code and
            dreams. I'm a 20-year-old intergalactic debugger based in the cosmic
            coding realm of Vancouver, navigating the constellations of code and
            turbulence of university. Outside of my space travels, I'm an explorer
            of anime, kpop, taekwondo and knitting. Debugger by day, gamer by
            night, and always on the lookout for the next tech marvel!
          </div>
        </div>
      </div>
    </Element>
  );
}
