import React from "react";
import SpaceCat3 from "./../images/space-cat3.png";
import "./css/MainPage.css";

export default function MainPage() {
  return (
    <div className="main-container">
      <div className="text-container">
        <div className="floatingText mainText">Michelle X. Wang</div>
        <div className="subText floatingText">
          Software developer & game enthusiast
        </div>
      </div>
      <div className="image-container">
        <img
          src={SpaceCat3}
          alt="space cat"
          className="floatingText"
        />
      </div>
    </div>
  );
}
